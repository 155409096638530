.containercartas{
    width: 100%;
    border: 0px solid;
    color: #1b1f22;
    display:flex;  
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.subcontainercartas{
    background-color:#1b1f22;
    border: 1px solid #6e2cf3;
    width: 470px;
    height: 620px;
    border-radius: 20px;
    margin: 10px 10px;
    box-shadow:  5px 5px 2.5px #4a1ea4;
}

.subcontainercartas a{
text-decoration: none;
color: #b3b3ba;
width: 100%;
border: 0px solid;
padding: 0px 50px;
}

.subcontainercartas h1{
    color: #b3b3ba;
}

.tabla-cartas{
    width: 100%;
    height: auto;
    border: 0px solid #9a0a0a;
}

.tabla-cartas th{
    width: 100%;
    height: auto;
    border: 0px solid #2411a4;
    cursor: pointer;
}

.tabla-cartas th:hover{
    background-color: #003249;
}

.divisorcartas1{
    background-color: #8ed6fb;
    width: 100%;
    height: 23px;
}

.divisorcartas2{
    background-color: #b43ffe;
    width: 100%;
    height: 23px;
}

.divisorcartas3{
    background-color: #1c78c0;
    width: 100%;
    height: 23px;
}