.Espacio-titulo{
    border: 0px solid #1E822B;
    height: 75px;
    align-content: center;
}

.Titulo{
    font-size: 30px;
    text-decoration: none;
    color: #b3b3ba;
    font-weight: 600;
}

.Guabito{
    font-size: 30px;
    color: #b3b3ba;
    font-weight: 600; 
    border: 0px solid #fff;
    margin: 0;
}

.containeraeropuertos{
    width: 100%;
    border: 0px solid #fff;
    color: #1b1f22;
    display:flex;  
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.subcontaineraeropuertos{
    background-color:#1b1f22;
    border: 1px solid #6e2cf3;
    width: 470px;
    height: 620px;
    border-radius: 20px;
    margin: 10px 10px;
    box-shadow:  5px 5px 2.5px #4a1ea4;   
}

.subcontaineraeropuertos a{
text-decoration: none;
color: #b3b3ba;
width: 100%;
border: 0px solid #fff;
padding: 0px 50px;
}

.subcontaineraeropuertos h1{
    color: #b3b3ba;
}

.container-tablas{
    border: 0px solid #fff;
    width: 100%;
    height: auto;
    display: flex;
}

.tabla-aeropuertos1{
    width: 50%;
    height: 23px;
    border: 0px solid #9a0a0a;
}

.tabla-aeropuertos2{
    width: 50%;
    height: 23px;
    border: 0px solid #ffffff;
}

.Procedimientos{
    width: 100%;
    height: auto;
    border: 0px solid #4e4e2e;
    cursor: pointer;
}

.Procedimientos:hover{
    background-color: #003249;
}

.divisoraeropuertos1{
    background-color: #1E822B;
    width: 100%;
    height: 23px;
}

.divisoraeropuertos2{
    background-color: #871C1C;
    width: 100%;
    height: 23px;
}

.divisoraeropuertos3{
    background-color: #D8BC3C;
    width: 100%;
    height: 23px;
}

.pista{
    text-align: center;
    font-size:18px;
}

