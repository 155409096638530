.contenedortea{
width: 100%;
height: auto;
display:flex;  
flex-wrap: wrap;
align-items: center;
justify-content: center;
}


.reproductor{
    width: 600px;
    max-width: 700px;
    margin: 0px 15px;
    height: auto;
    border: 0px solid;
}

.reproductor2{
    width: 600px;
    max-width: 700px;
    margin: 0px 15px;
    height: auto;
    border: 0px solid;
    justify-content: center;
    
}

.pictures{
    font-size: 28px;
    text-decoration: none;
    color: #b3b3ba;
    padding: 15px ;
}

.slide{
    width: 100%;
}