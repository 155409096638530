.menu {
  background-color: #6e2cf3;
  overflow: hidden; 
  border: 0px solid;
  font-weight: 500;
}

.menu a{
text-decoration: none;
color: #b3b3ba;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  float:left;
  display: inline;
  color: #b3b3ba;
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  border-radius: 3px;
}

.menu-item:hover {
  background-color: #003249; 
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #671dfa;
  width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;  
  height: 365px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-menu .dropdown-item {  
  color: #003249;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu .dropdown-item a{  
  border: 0px solid #fff;
  padding: 0px 35px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #003249;  
  border-radius: 10px;
}

.dropdown-menu.show {
  display: block;
}
